import React from "react";
import { useParams } from "react-router";
import { useGetChallengeById } from "hooks/Challenges";
import Flex from "app/components/Flex";
import { P1, H4 } from "app/components/Typography";

export default () => {
  const { id } = useParams();
  const [{ description = "" }] = useGetChallengeById({
    variables: { id },
  });

  const customDescription = description.split("\n");

  return (
    <Flex flexDirection="column" mb={3}>
      <H4 mb={3}>About Challenge</H4>
      <P1 maxWidth="700px">
        {customDescription.map(line => (
          <p>{line}</p>
        ))}
      </P1>
    </Flex>
  );
};
